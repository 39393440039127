@import '../../../../scss/theme-bootstrap';

.custom-links-block {
  &__item,
  &__link {
    border-bottom: none;
    color: inherit;
    font-family: $ano-bold_regular-font;
    font-size: 40px;
    line-height: 1;
    @include breakpoint($bp--medium-up) {
      font-size: 64px;
    }
    @if $fonts_update == true {
      font-family: $ano-black_regular-font;
      letter-spacing: 0;
      line-height: 0;
    }
  }
  &__item {
    color: $color--white;
    a {
      color: inherit;
    }
  }
  &__link {
    transition: all 0.3s;
    display: inline-block;
    white-space: nowrap;
    &.disabled {
      color: $color--gray--light;
    }
  }
}
